<template>
<div style="margin: 20px;">
  <SearchCondition @onSearch="onSearch"/>
  <!-- <CustomTreeSelectMulti /> -->
  <ScatterChart />
</div>
</template>

<script>
import SearchCondition from "../../components/SearchCondition.vue"
import CustomTreeSelectMulti from "../../components/CustomTreeSelectMulti"
import ScatterChart from "../../views/menu/Recommendation/components/ScatterChart.vue"
export default {
  components: {
    SearchCondition,
    CustomTreeSelectMulti,
    ScatterChart,
  },
  mounted() {
    this.$axios.post("/api/dada/analyze_nps", {
        "startTime":"",
        "endTime":""
    }).then(res => {
      console.log(res)
    })
  },
  methods: {
    onSearch(val) {
      console.log(val)
    }
  }
}
</script>

<style>

</style>